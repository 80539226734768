<template>
  <div>
    <Breadcrumbs page_title="settingposition" :items="breadcrumbs" />

    <v-container fluid>
      <v-card v-if="showDataPage && isoaccess" class="rounded-lg">
        <v-card>
          <v-row class="ma-1">
            <v-col
              cols="12"
              md="11"
              lg="11"
              xl="11"
              sm="11"
              class="pl-4 pt-5 pr-3 pb-1"
            >
              <div
                class="table-wrapper"
                style="padding-bottom: 25px !important"
                id="div1"
                @scroll="syncScroll"
              >
                <tr>
                  <td class="section-column text-center" rowspan="3">
                    {{ $t("section") }}
                  </td>
                  <td>
                    <table
                      class="custom-main-table"
                      aria-describedby="main-table"
                    >
                      <tr>
                        <th
                          scope
                          v-for="(item, i) in topics"
                          :key="i"
                          class="main-topic-cell"
                        >
                          {{ item.mainTopic }}
                        </th>
                      </tr>

                      <tr>
                        <td
                          v-for="topic in topics"
                          :key="topic.mainTopicId"
                          class="sub-topic-cell"
                        >
                          <table
                            class="custom-sub-table"
                            aria-describedby="sub-table"
                          >
                            <th
                              v-for="(subitem, i) in WhereSubTopic(
                                topic.mainTopicId
                              )"
                              :key="i"
                              scope
                              class="sub-topic-cell-bg"
                            >
                              <div
                                class="subname-custom webkit-line-clamp-3-lines"
                                :style="[
                                  subitem.textSubLength > 50
                                    ? subitem.course_count == 1
                                      ? { width: '150px !important' }
                                      : { width: 'auto' }
                                    : subitem.textSubLength >= 30
                                    ? subitem.course_count == 1
                                      ? { width: '135px !important' }
                                      : { width: 'auto' }
                                    : { width: 'auto' },

                                  { height: subTopicHeight },
                                ]"
                              >
                                {{ subitem.subTopic }}
                              </div>
                            </th>
                            <tr>
                              <td
                                v-for="(subitem, i) in WhereSubTopic(
                                  topic.mainTopicId
                                )"
                                :key="i"
                                style="padding: 0px !important"
                              >
                                <table
                                  class="custom-course-table"
                                  aria-describedby="course-table"
                                >
                                  <th v-if="false" scope></th>
                                  <tr>
                                    <td
                                      v-for="(
                                        courseItem, i
                                      ) in WhereCoursesOfSubTopic(
                                        subitem.subTopicId
                                      )"
                                      :key="i"
                                      class="course-varticle"
                                    >
                                      <span
                                        :class="[
                                          courseItem.courseLength > 55
                                            ? 'course-container'
                                            : 'rotated-text',
                                        ]"
                                        >{{ courseItem.course }}</span
                                      >
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </div>
            </v-col>
            <v-col cols="12" md="1" lg="1" xl="1" sm="1"></v-col>
          </v-row>
          <v-row
            class="ma-1"
            v-for="(dept_item, i) in usersOfDeptList"
            :key="dept_item.departmentId"
          >
            <v-col
              cols="12"
              md="11"
              lg="11"
              xl="11"
              sm="11"
              class="pl-4 pr-3 pt-4 pb-2"
            >
              <!-- department data info table -->
              <div
                class="table-wrapper"
                :id="'div' + (i + 1)"
                @scroll="syncScroll"
              >
                <tr v-if="addDept">
                  <td style="vertical-align: top">
                    <tr>
                      <th scope class="dept-large-column">
                        <span class="ml-1" style="font-size: 16px">{{
                          $t("departmentl")
                        }}</span>
                        <br />
                        <span
                          class="ml-1 white--text mb-3"
                          style="
                            font-size: 13px;
                            background-color: #ffc900;
                            border: 1px solid #ffc900;
                            padding: 2px;
                            border-radius: 6px;
                          "
                          >{{ dept_item.departmentName }}</span
                        >
                      </th>
                    </tr>
                    <tr>
                      <td colspan="1" class="dept-user-col-large">
                        <span class="ml-1" style="color: #4390de">{{
                          $t("usernamel")
                        }}</span>
                      </td>
                    </tr>
                    <tr v-for="(userItem, i) in dept_item.user" :key="i">
                      <td colspan="1" class="dept-username-col-large">
                        <div class="username-custom">
                          {{ userItem.fullName }}
                        </div>
                      </td>
                    </tr>
                  </td>
                  <td>
                    <table
                      class="custom-dept-table mb-6"
                      aria-describedby="dept-table"
                    >
                      <tr>
                        <th
                          v-for="dept in dept_item.Courses"
                          :key="dept.CourseIndex"
                          class="dept-col-cell"
                          scope
                        >
                          <v-checkbox
                            class="mt-0 ml-1 sc-checkbox"
                            v-model="dept.flagCourseSelect"
                            @change="
                              toggleAllCheckboxes(
                                dept_item.departmentId,
                                dept.courseId,
                                dept.flagCourseSelect
                              )
                            "
                            color="#A6CC39"
                            hide-details
                          ></v-checkbox>
                        </th>
                      </tr>
                      <tr
                        style="
                          border-left: 1px solid #707070b3;
                          border-right: 1px solid #707070b3;
                        "
                      >
                        <td
                          v-for="(text, k) in dept_item.courseUI"
                          :key="k"
                          class="dept-userEmpty-col"
                          :style="[
                            text.textSubLength > 50
                              ? text.course_count == 1
                                ? { 'min-width': '151.6px !important' }
                                : { 'min-width': 'auto' }
                              : text.textSubLength >= 30
                              ? text.course_count == 1
                                ? { 'min-width': '136px !important' }
                                : { 'min-width': 'auto' }
                              : { 'min-width': 'auto' },
                          ]"
                        ></td>
                      </tr>

                      <tr
                        v-for="userItem in dept_item.user"
                        :key="userItem.userId"
                      >
                        <td
                          v-for="(iso_course, l) in userItem.isoCourseList"
                          :key="l"
                          class="dept-user-col"
                        >
                          <!-- Select by one -->
                          <v-checkbox
                            class="mt-0 ml-1 sc-checkbox"
                            v-model="iso_course.flagCourseSelect"
                            color="#A6CC39"
                            hide-details
                          ></v-checkbox>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </div>
            </v-col>
            <v-col cols="12" md="1" lg="1" xl="1" sm="1">
              <v-row v-if="addDept">
                <v-col cols="12" class="pl-0 pr-0">
                  <v-btn
                    @click="scrollToBottom(dept_item)"
                    width="20"
                    height="30"
                    class="smallBtnCourse"
                  >
                    <v-icon center small>mdi-arrow-down</v-icon>
                  </v-btn>

                  <v-btn
                    @click="scrollToTop(dept_item)"
                    class="ml-1 smallBtnCourse"
                    width="20"
                    height="30"
                  >
                    <v-icon center small>mdi-arrow-up</v-icon>
                  </v-btn>

                  <v-btn
                    width="20"
                    height="32"
                    class="ml-1 dept-deleteBtn"
                    @click="DeleteDepartment(dept_item)"
                  >
                    <v-icon center small>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="ma-1">
            <v-col
              cols="12"
              md="8"
              lg="11"
              xl="11"
              sm="11"
              class="pt-0"
              style="text-align: center"
            >
              <v-btn
                class="text-capitalize white--text TRtopicsavebtn"
                color="#A6CC39"
                @click="openDepartmentDialog()"
                >{{ $t("adddepartmentone") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="addDeptActionButtons === 'true'" class="ma-1">
            <v-col
              cols="12"
              md="7"
              lg="11"
              xl="11"
              sm="11"
              class="pt-0 pb-6"
              style="text-align: right"
            >
              <v-btn
                outlined
                class="text-capitalize"
                width="100"
                style="font-size: 16px; color: #424242"
                text
                @click="Cancel()"
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                class="text-capitalize ml-3 white--text sendbtn"
                width="100"
                color="#A6CC39"
                style="font-size: 16px"
                @click="SaveDepartment()"
                >{{ $t("save") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-card>
      <v-card
        v-else
        class="rounded-lg"
        height="100vh"
        style="background-color: #e0e0e07a"
      ></v-card>
    </v-container>

    <v-dialog v-model="addDeptDialog" width="500" scrollable persistent>
      <v-card style="border-radius: 20px">
        <v-card-title
          style="font-size: 20px; color: #363636; border: 1px solid #c0c0c0"
          class="pb-4"
        >
          <div>
            <v-icon class="mr-1" style="padding-bottom: 3px"
              >mdi-plus-box-outline</v-icon
            >
            {{ $t("adddepartmentone") }}
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              icon
              @click="addDeptDialog = false"
              color="#424242"
              style="border-radius: 8px !important"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text style="height: 600px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-1">
              <v-col cols="12" sm="12" md="10" lg="10">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  style="border-radius: 8px; border-color: #c0c0c0"
                  clearable
                  prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('search')"
                  v-model="searchDept"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span style="color: red">{{
                  $t("only1departmentcanbeselected")
                }}</span>
                <div class="mt-1">
                  <v-data-table
                    :headers="departmentListHeader"
                    :items="departments"
                    hide-default-footer
                    class="assign-table"
                    :search="searchDept"
                    @page-count="deptpageCount = $event"
                    :page.sync="dialogpage"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td class="text-xs-center" style="width: 40px">
                          <v-radio-group
                            v-model="selectedDept"
                            name="rowSelector"
                            class="tableRadioBtn"
                            hide-details
                          >
                            <v-radio :value="item.departmentId" />
                          </v-radio-group>
                        </td>
                        <td class="text-xs-start">{{ item.departmentName }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                  <v-row justify="end">
                    <div class="mt-4 mb-0 mr-1 d-flex">
                      <v-pagination
                        circle
                        v-model="dialogpage"
                        class="pagination"
                        :length="deptpageCount"
                      ></v-pagination>
                    </div>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="mt-1 pb-5">
          <v-spacer></v-spacer>
          <v-btn
            text
            class="text-capitalize btnfont-style"
            width="100"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            @click="addDeptDialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            @click="SelectDepartment()"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogStart" width="400" persistent>
      <v-card style="border-radius: 16px">
        <v-card-title>
          <v-row justify="center" no-gutters>
            <v-col style="text-align: center" class="pt-2 pb-2">
              <span class="mt-2" style="font-size: 20px; color: #363636">{{
                $t("plzfilloutsectionsettingmenubeforeselectingposition")
              }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row no-gutters>
            <v-col style="text-align: center">
              <v-btn
                color="#A6CC39"
                width="156"
                height="60"
                style="font-size: 16px; border-radius: 8px; box-shadow: unset"
                class="text-capitalize white--text mt-1 mb-1"
                @click="
                  $router.push('/trainingroadmap/settingsection'),
                    (dialogStart = false)
                "
              >
                <div
                  style="
                    width: 156px;
                    height: 39px;
                    word-break: break-all !important;
                    white-space: normal;
                  "
                >
                  {{ $t("opensettingsectionmenu") }}
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="crudloading" v-if="isoaccess" persistent width="300">
      <v-card color="#4FB14E" dark style="border-radius: 4px !important">
        <v-card-text class="white--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#ffffff"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data: () => ({
    permissionDialog: false,
    permissionMessage: "",
    deptOriginal: [],
    takenDeptIds: [],
    arrangeResult: [],
    subTopicHeight: "auto",
    createrId: null,
    crudloading: false,
    updateDept: null,
    //loading: true,
    isoAssignmentList: [],
    // dept dialog
    selectedDept: 0,
    dialogpage: 1,
    deptpageCount: 0,
    departments: [],
    valid: false,
    searchDept: null,
    addDeptDialog: false,
    //...................
    savedDept: false,
    addDept: false,
    addDeptActionButtons: null,
    dialogStart: false,
    //........................
    showDataPage: false,
    breadcrumbs: [
      {
        sidebar_tilte: "trainingroadmap",
      },
      {
        text: "settingposition",
      },
    ],
    assignmentId: 0, //setting section data id of this company
    lastDept: null,
    usersOfDeptList: [],
    topics: [],
    sub_topics: [],
    courses_list: [],
    //departmentAssignmentList: [],
    userAssignmentList: [],
    isoaccess: false,
  }),
  computed: {
    departmentListHeader() {
      return [
        {
          text: "",
          align: "left",
          value: "select",
        },
        {
          text: this.$t("departmentl"),
          align: "left",
          value: "departmentName",
        },
      ];
    },
  },
  mounted() {
    const accessqr = localStorage.getItem("isoaccess");
    this.isoaccess = JSON.parse(accessqr);

    const userdata = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    this.createrId = userdata.memberId;

    (async () => {
      await this.getISOAssignment();
      this.getDepartment();

      return Promise.resolve();
    })().then(() => {
      this.mountDone = true;
    });
  },
  methods: {
    openDepartmentDialog() {
      let checkPermission = this.check_Permissions("trainingroadmapiso");
      if (!checkPermission) {
        this.permissionDialog = true;
        this.permissionMessage = "You don't have permission to add department.";
      } else {
        //filtered out selected dept
        let copyDept = this.deptOriginal;
        let temp_dept = copyDept.filter(
          (d) => !this.takenDeptIds.includes(d.departmentId)
        );

        this.departments = temp_dept;
        this.addDeptDialog = true;
      }
    },
    arrangeCourseList() {
      console.log("topics =>", this.topics);
      console.log("sub_topics =>", this.sub_topics);
      console.log("courses => ", this.courses_list);

      for (let t_ele of this.topics) {
        let subTemp = this.WhereSubTopic(t_ele.mainTopicId);
        for (let s_ele of subTemp) {
          let courseTemp = this.WhereCoursesOfSubTopic(s_ele.subTopicId);
          for (let c_ele of courseTemp) {
            const ans = {
              assignmentId: c_ele.assignmentId,
              idOfCourse: c_ele.idOfCourse,
              courseId: c_ele.courseId,
              course: c_ele.course,
              flagCourseSelect: c_ele.flagCourseSelect,
              course_count: s_ele.course_count,
              textSubLength: s_ele.textSubLength,
            };
            this.arrangeResult.push(ans);
          }
        }
      }
      console.log("Arrange Courses :", this.arrangeResult);
    },
    syncScroll(event) {
      const scrollLeft = event.target.scrollLeft;

      // Apply the same scroll position to all divs
      const divs = this.$el.querySelectorAll(".table-wrapper");

      divs.forEach((div) => {
        div.scrollLeft = scrollLeft;
      });
    },
    DeleteDepartment(d) {
      let self = this;

      let checkPermission = self.check_Permissions("trainingroadmapiso");
      if (!checkPermission) {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to access this function.";
      } else {

        let deptListTemp = self.usersOfDeptList;
        let lastDeptCheck = self.usersOfDeptList.length;
        if (lastDeptCheck === 1) {
          self.lastDept = self.usersOfDeptList[0];
        }
        const indexToDelete = self.usersOfDeptList.findIndex(
          (item) => item.departmentId === d.departmentId
        );
        if (indexToDelete !== -1) {
          deptListTemp.splice(indexToDelete, 1);
        }
        self.usersOfDeptList = deptListTemp;
        self.takenDeptIds = deptListTemp.map((y) => y.departmentId);
      }
    },
    //get dept that had already saved
    async getPreviousDepartment() {
      let self = this;
      self.crudloading = true;
      axios
        .post(`${self.web_url}ISO/GetISOAssignmentDepartment_V3`, {
          companyId: localStorage.getItem("companyID"),
        })
        .then(function (response) {
          console.log("Getting Edit Data ", response.data.data);
          if (response.data.status === 0) {
            self.crudloading = false;
            if (response.data.data) {
              self.updateDept =
                response.data.data.departmentList?.length > 0
                  ? "true"
                  : "false";
              let iso_deptlist = response.data.data.departmentList || [];

              const subTopicsMap = new Map(
                self.sub_topics.map((s) => [s.subTopicId, s])
              );

              for (let u in iso_deptlist) {
                let courseTemp = iso_deptlist[u].isoCourseList.map((l) => ({
                  ...l,
                  flagCourseSelect: l.flagCourseSelect, //false,
                  CourseIndex: l.courseId + self.usersOfDeptList.length,
                  course_count: subTopicsMap.get(l.subTopicId)?.course_count,
                  // self.sub_topics.find(
                  //   (s) => s.subTopicId === l.subTopicId
                  // ).course_count,
                  textSubLength: subTopicsMap.get(l.subTopicId)?.textSubLength,
                  // self.sub_topics.find(
                  //   (s) => s.subTopicId === l.subTopicId
                  // ).textSubLength
                }));

                const tempItem = {
                  departmentName: iso_deptlist[u].departmentName,
                  departmentId: iso_deptlist[u].departmentId,
                  departmentNo: self.usersOfDeptList.length + 1,
                  departmentUserAssignmentId:
                    iso_deptlist[u].departmentUserAssignmentId,
                  departmentAssignmentId:
                    iso_deptlist[u].departmentAssignmentId,
                  companyId: localStorage.getItem("companyID"),
                  assignmentId: self.assignmentId,
                  Courses: courseTemp,
                  courseUI: self.arrangeResult,
                  user: iso_deptlist[u].user,
                };
                self.usersOfDeptList.push(tempItem);
              }
              self.takenDeptIds = iso_deptlist.map((x) => x.departmentId);
              console.log("get edit dept data : ", self.usersOfDeptList);
              self.addDept = true; //show dept table list
              self.addDeptActionButtons =
                self.usersOfDeptList.length > 0 ? "true" : "false"; //show save / cancel button
              console.log("Update or not ", self.updateDept);
            }

            self.dialogStart = self.topics.length == 0; // ? false : true;
            self.showDataPage = self.topics.length !== 0; // ? true : false;
          } else {
            //in case setting section is ok but department data is not ok
            self.crudloading = false;
            self.dialogStart = self.topics.length == 0; //? false : true;
            self.showDataPage = self.topics.length !== 0; // ? true : false;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    mappingUserdata(element2, assignmentId) {
      return {
        userDataId: element2.userId ?? element2.userDataId,
        fullName: element2.fullName,
        departmentUserAssignmentId: element2.departmentUserAssignmentId || 0,
        userAssignmentId: element2.userAssignmentId || 0,
        departmentId: element2.departmentId,
        companyId: localStorage.getItem("companyID"),
        assignmentId: assignmentId,
        mainTopicId: element2.mainTopicId || 0,
        subTopicId: element2.subTopicId || 0,
        courseId: element2.courseId || 0,
        isoCourseList: element2.isoCourseList.map((element3) => ({
          ...element3,
          courseNo_Order:
            element3.courseNo_Order !== 0 ? element3.courseNo_Order : 0,
          courseNo_Show: element3.courseNo_Show ? element3.courseNo_Show : null,
          companyId: localStorage.getItem("companyID"),
          createOrUpdateBy: this.createrId,
        })),
        createOrUpdateBy: this.createrId,
      };
    },
    prepareDataToSave(userDeptData) {
      let temp = [];
      let deptNo_ShowingOrder = 1;
      for (let item of userDeptData) {
        let dept_templist = {
          departmentId: item.departmentId,
          departmentNo: deptNo_ShowingOrder++, //item.departmentNo,
          departmentName: item.departmentName,
          departmentUserAssignmentId: item.departmentUserAssignmentId,
          departmentAssignmentId: item.departmentAssignmentId,
          companyId: localStorage.getItem("companyID"),
          assignmentId: item.assignmentId,
          isoCourseList: [],
          user: [],
          createOrUpdateBy: this.createrId,
        };

        for (let element1 of item.Courses) {
          const isocourse = {
            ...element1,
            courseNo_Order:
              element1.courseNo_Order !== 0 ? element1.courseNo_Order : 0,
            courseNo_Show: element1.courseNo_Show
              ? element1.courseNo_Show
              : null,
            companyId: localStorage.getItem("companyID"),
            createOrUpdateBy: this.createrId,
          };
          dept_templist.isoCourseList.push(isocourse);
        }

        const prepareUserdata = item.user.map((element2) =>
          this.mappingUserdata(element2, item.assignmentId)
        );
        dept_templist.user.push(...prepareUserdata);

        temp.push(dept_templist);
      }
      return temp;
    },
    async SaveDepartment() {
      let self = this;

      let checkPermission = self.check_Permissions("trainingroadmapiso");
      if (!checkPermission) {
        self.permissionDialog = true;
        self.permissionMessage = "You don't have permission to save.";
      } else {
        let lastDeptCheck = self.usersOfDeptList.length;
        if (lastDeptCheck === 0) {
          alert(
            "Cannot delete all department, at least 1 Department must be remain to save or update Department."
          );
          self.usersOfDeptList.push(self.lastDept);
          return;
        }

        self.crudloading = true;

        console.log("Dept data => ", self.usersOfDeptList);
        console.log("Update Depatment => ", self.updateDept);

        let request = [];
        request = self.prepareDataToSave(self.usersOfDeptList);

        console.log("request data to save dept => ", request);

        let success_text = "";

        if (self.updateDept === "true") {
          success_text = "Update Department Successful.";
        } else {
          success_text = "Add Department Successful.";
        }

        axios
          .post(`${self.web_url}ISO/AddOrEditDepartmentUserAssignment`, {
            companyId: localStorage.getItem("companyID"),
            departmentList: request,
            createOrUpdateBy: self.createrId,
          })
          .then(function (response) {
            console.log("Add or Update Success ", response.data.status);
            if (response.data.status == 0) {
              console.log("Success Add or Update dept");
              self.crudloading = false;
              alert(success_text);
              self.usersOfDeptList = [];
              self.$router.push("/trainingroadmap/skillchart");
            }
          })
          .catch(function (err) {
            throw err;
          });
      }
    },
    Cancel() {
      console.log("Cancel saving department.");
      this.addDeptActionButtons = "false";
    },
    toggleAllCheckboxes(d, c, f) {
      let userlist = this.usersOfDeptList.find((m) => m.departmentId == d)
        ? this.usersOfDeptList.find((m) => m.departmentId == d).user
        : null;
      if (userlist) {
        for (let element of userlist) {
          element.isoCourseList.forEach((el) => {
            if (el.courseId == c) {
              el.flagCourseSelect = f;
            }
          });
        }
        this.usersOfDeptList.find((m) => m.departmentId == d).user = userlist;
      }
    },
    WhereSubTopic(Id) {
      return this.sub_topics.filter((x) => x.mainTopicId == Id);
    },
    WhereCoursesOfSubTopic(subId) {
      return this.courses_list.filter((x) => x.subTopicId == subId);
    },
    async getISOAssignment() {
      console.log("Compony id :", localStorage.getItem("companyID"));
      let self = this;
      self.crudloading = true;
      axios
        .post(`${self.web_url}ISO/GetISOAssignmentDepartment`, {
          companyId: localStorage.getItem("companyID"),
        })
        .then(function (res) {
          console.log("ISO/GetISOAssignmentDepartment ", res.data.data);

          if (res.data.status === 0) {
            let check_maintopic = res.data.data.mainTopicList?.length > 0;
            self.crudloading = false;

            if (check_maintopic === false) {
              self.dialogStart = true;
              return false;
            } else {
              self.topics = res.data.data.mainTopicList;
              self.sub_topics = res.data.data.subTopicList;
              self.courses_list = res.data.data.courseList.map((cou) => ({
                ...cou,
                courseLength: cou.course.length,
              }));
              self.userAssignmentList = res.data.data.userAssignmentList;
              self.assignmentId = res.data.data.assignmentId;

              let topic_t = [];
              //for sub topic width control
              for (let t1 in self.topics) {
                const mainTopicId = self.topics[t1].mainTopicId;
                const temp_item = self.sub_topics.filter(
                  (n) => n.mainTopicId === mainTopicId
                );
                const newItem = {
                  ...self.topics[t1],
                  textMainLength: self.topics[t1].mainTopic.length,
                  subtopic_count: temp_item.length,
                };
                topic_t.push(newItem);
              }
              self.topics = topic_t;

              let subtopic_t = [];
              //for sub topic width control
              for (let c in self.sub_topics) {
                const subTopicId = self.sub_topics[c].subTopicId;
                const coursesWithSameSubTopicId = self.courses_list.filter(
                  (s) => s.subTopicId === subTopicId
                );
                const newItem = {
                  ...self.sub_topics[c],
                  textSubLength: self.sub_topics[c].subTopic.length,
                  course_count: coursesWithSameSubTopicId.length,
                  textSubHeight: self.setHeight(
                    self.sub_topics[c].subTopic.length,
                    coursesWithSameSubTopicId.length
                  ),
                };
                subtopic_t.push(newItem);
              }
              self.sub_topics = subtopic_t;

              console.log("topics ", self.topics);
              console.log("sub topics ", self.sub_topics);
              console.log("course list ", self.courses_list);

              self.arrangeCourseList();
              self.controlHeightOfSubTopic();

              self.getPreviousDepartment();
            }
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    setHeight(length, count) {
      let temp_h = 0;

      if (length > 50) {
        temp_h = count > 3 ? 0 : 75;
      } else if (length > 35) {
        temp_h = count > 3 ? 0 : 67;
      } else {
        temp_h = count > 3 ? 0 : 50;
      }
      return temp_h;
    },
    controlHeightOfSubTopic() {
      let self = this;
      let heightTemp = self.sub_topics.reduce(
        (max, obj) => (obj.textSubHeight > max.textSubHeight ? obj : max),
        self.sub_topics[0]
      );
      self.subTopicHeight = heightTemp.textSubHeight + "px";
    },
    async getDepartment() {
      let self = this;

      axios
        .post(`${this.web_url}ISO/GetAllDepartment`, {
          companyId: localStorage.getItem("companyID"),
        })
        .then(function (res) {
          if (res.data.status == 0) {
            self.departments = res.data.data.department.map((v, i) => ({
              ...v,
              index: i + 1,
            }));
            self.deptOriginal = res.data.data.department;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async SelectDepartment() {
      let self = this;
      console.log("Selected Dept : ", self.selectedDept);

      if (self.selectedDept == 0) {
        //user does not select and click save condition
        alert("Select Department.");
        return false;
      }

      self.addDeptDialog = false;
      let data = {
        companyId: localStorage.getItem("companyID"),
        departmentId: self.selectedDept,
      };

      axios
        .post(`${self.web_url}ISO/GetUserOfDepartment_V2`, data)
        .then(function (res) {
          if (res.data.status == 0) {
            console.log("Dept's users ", res.data.data); //it has both departments info which include isocourseList...

            let dept_ = res.data.data.department;
            console.log("departments' user ", dept_);

            let tempC = dept_.isoCourseList.map((l) => ({
              ...l,
              flagCourseSelect: false,
              CourseIndex: l.courseId + self.usersOfDeptList.length,
              course_count: self.sub_topics.find(
                (s) => s.subTopicId === l.subTopicId
              ).course_count,
            }));

            let dept_user = res.data.data.users
              ? res.data.data.users.map((v, i) => ({
                  ...v,
                  i: i + 1,
                }))
              : [];
            const newItemObject = {
              departmentName: dept_.departmentName,
              departmentId: dept_.departmentId,
              departmentNo: self.usersOfDeptList.length + 1,
              departmentUserAssignmentId: 0,
              departmentAssignmentId: 0,
              companyId: localStorage.getItem("companyID"),
              assignmentId: self.assignmentId,
              Courses: tempC,
              courseUI: self.arrangeResult,
              user: dept_user,
            };
            self.usersOfDeptList.push(newItemObject);
            console.log("ISO/GetUserOfDepartment => ", self.usersOfDeptList);
            self.takenDeptIds.push(self.selectedDept); //get selected dept id
            self.addDept = true; //show dept table list
            self.addDeptActionButtons = "true";
            self.selectedDept = 0;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    scrollToBottom(item) {
      if (this.usersOfDeptList.length <= 1) {
        //if only one dept is in the list won't need to scroll
        return false;
      }

      const deptIndex = this.usersOfDeptList.findIndex(
        (usersOfDeptList) => usersOfDeptList.departmentId == item.departmentId
      );
      // Check if the clicked index is not the last index
      if (deptIndex < this.usersOfDeptList.length - 1) {
        // Swap the clicked department with the next one
        const selectedDept = this.usersOfDeptList.splice(deptIndex, 1)[0];
        // Insert the removed department to the next index
        this.usersOfDeptList.splice(deptIndex + 1, 0, selectedDept);
      }
    },
    scrollToTop(item) {
      if (this.usersOfDeptList.length <= 1) {
        //if only one dept is in the list won't need to scroll
        return false;
      }

      const deptIndex = this.usersOfDeptList.findIndex(
        (usersOfDeptList) => usersOfDeptList.departmentId == item.departmentId
      );
      // Check if the clicked index is not the first index
      if (deptIndex > 0) {
        // Remove the clicked department from its current position
        const selectedDept = this.usersOfDeptList.splice(deptIndex, 1)[0];
        // Insert the removed department to the previous index
        this.usersOfDeptList.splice(deptIndex - 1, 0, selectedDept);
      }
      console.log("result : ", this.usersOfDeptList);
    },
  },
};
</script>

<style scoped>
.custom-main-table {
  border-collapse: collapse !important;
  width: 100%;
  border: 1px solid #a6cc39;
  border-radius: 8px 0px 0px 0px;
  word-break: break-all;
}

.custom-main-table th {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.custom-sub-table {
  border-collapse: collapse !important;
  width: 100%;
}

.custom-course-table {
  border-collapse: collapse !important;
  width: 100%;
  padding: 0px !important;
}
.custom-course-table td {
  padding: 0px !important;
}
.main-topic-cell {
  background-color: rgb(103, 171, 48, 0.15);
  color: #707070;
  text-align: center;
  border: 1px solid #a6cc39;
  width: 99px;
}
.sub-topic-cell-bg {
  background-color: rgb(103, 171, 48, 0.29);
  color: #707070;
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #a6cc39;
  width: 99px !important;
}
.sub-topic-cell {
  background-color: #fff;
  padding: 0px !important;
}
.sub-topic-cell td {
  text-align: center !important;
  vertical-align: bottom;
  min-width: 99px;
  height: 250px;
  word-break: break-all;
}
.course-varticle {
  height: 249px;
  border-left: 1px solid #a6cc39;
}
.rotated-text {
  color: #67ab30;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  text-align: start;
  height: 250px;
  overflow: hidden;
  word-break: break-all;
}
.course-container {
  color: #67ab30;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  text-align: start;
  height: 250px;
  overflow: hidden;
  word-break: break-all;
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Department table css */
.dept-large-column {
  color: #424242;
  width: 223px !important;
  height: 55px;
  border: 1px solid rgb(112, 112, 112, 0.7);
  font-weight: normal;
  background-color: rgb(255, 201, 0, 0.05);
}
.dept-col-cell {
  background-color: rgb(255, 201, 0, 0.05);
  text-align: center !important;
  justify-content: center;
  align-items: center;
  height: 54px;
  border: 1px solid rgb(112, 112, 112, 0.7);
}
.custom-dept-table {
  border-collapse: collapse !important;
  width: 100%;
  border-top: 1px solid rgb(112, 112, 112, 0.7);
  border-bottom: 1px solid rgb(112, 112, 112, 0.7);
  border-radius: 8px 0px 0px 0px;
}
.dept-user-col-large {
  height: 43px;
  vertical-align: middle;
  text-align: center;
  border: 1px solid rgb(112, 112, 112, 0.7);
  background-color: rgb(204, 204, 204, 0.27);
}
.dept-userEmpty-col {
  height: 43.5px !important;
  min-width: 101.5px;
  width: 99.6px;
  text-align: center;
  background-color: rgb(204, 204, 204, 0.27);
}
.dept-username-col-large {
  width: 223px;
  height: 45px;
  vertical-align: middle;
  text-align: center;
  border: 1px solid rgb(112, 112, 112, 0.7);
  background-color: #ffffff;
}
.username-custom {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
  padding-left: 2px;
  width: 223px;
  color: #424242;
}
.dept-user-col {
  vertical-align: middle;
  text-align: center;
  border: 1px solid rgb(112, 112, 112, 0.7);
  background-color: #ffffff;
  height: 45px;
  min-width: 99.6px;
}
.tableBtnSC.v-btn:not(.v-btn--round).v-size--small {
  font-size: 12px;
  height: 28px;
  min-width: 30px;
  border-radius: 8px !important;
  padding: 0 5px;
}
</style>

<style scoped>
.TRtopicsavebtn {
  font-size: 18px;
  border-radius: 8px;
}
::v-deep .v-dialog {
  box-shadow: unset !important;
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
}
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  opacity: 1;
  text-align: center;
  background-color: #e0e0e0 !important;
  border-color: #e0e0e0 !important;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  opacity: 1;
}
::v-deep .primary--text {
  color: #67ab30 !important;
  caret-color: #67ab30 !important ;
}
</style>

<style scoped>
::v-deep
  .sc-checkbox.v-input--hide-details
  > .v-input__control
  > .v-input__slot {
  margin-bottom: 5px;
  justify-content: center !important;
}
.tableRadioBtn.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.smallBtnCourse.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: unset;
  padding: 0 12px;
  color: #ffffff;
  background-color: #a6cc39;
  top: 5px !important;
}
.dept-deleteBtn.v-btn:not(.v-btn--round).v-size--default {
  background-color: #ff6060 !important;
  border-radius: 8px !important;
  color: #ffffff;
  box-shadow: unset;
  height: 36px;
  min-width: 22px !important;
  padding: 0 12px !important;
  top: 5px !important;
}

::v-deep .table-wrapper {
  overflow-x: auto;
  scrollbar-width: 0px !important; /* For Firefox */
  scrollbar-color: #888 #f5f5f5; /* For Chrome, Edge, and Safari */
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: #88888859; /*#888;*/
  border-radius: 5px;
  height: 7px;
}
.table-wrapper::-webkit-scrollbar {
  height: 9px;
}

.table-wrapper::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 4px;
}
</style>

<style scoped>
.section-column {
  color: #707070;
  font-size: 24px;
  vertical-align: middle;
  min-width: 226px;
  background-color: rgb(166, 204, 57, 0.55);
  border: 1px solid #a6cc39;
}
</style>

<style scoped>
::v-deep .subname-custom {
  overflow: hidden;
  width: 99px;
}
.webkit-line-clamp-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>